import axios from 'axios'
import {Message} from 'element-ui'
import router from '@/router'
import qs from 'querystring'
// create an axios instance
const reqUrl = process.env.VUE_APP_API_BASE_URL;

const service = axios.create({
  params: {
    merchantId: process.env.VUE_APP_MerId
  },
  baseURL: reqUrl,
  timeout: 5000 // request timeout
})

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.result) {
      return res
    } else if (res.code) {
      Message({message: res.message, type: 'error', duration: 5 * 1000})
      window.localStorage.removeItem('token')
      setTimeout(() => {
        router.replace({
          path: "/Index",
        })
      }, 500)
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      Message({message: res.message, type: 'error', duration: 5 * 1000})
      return Promise.reject(new Error(res.message || 'Error'))
    }
    // if (res.status !== 200) {
    //     Message({message: res.message, type: 'error', duration: 5 * 1000})
    //     if (res.status === 401) {
    //         logout()
    //     }
    //     return Promise.reject(new Error(res.message || 'Error'))
    // }
  },
  error => {
    console.log('**' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export function get(url, params) {
  return service.get(url, {
    params, // get 请求时带的参数
    timeout: 10000,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      token: window.localStorage.getItem('token') || ''
    }
  })
}

export function getFromData(url, params) {
  return service.get(url, {
    params, // get 请求时带的参数
    timeout: 10000,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      token: window.localStorage.getItem('token') || ''
    }
  })
}

export function del(url, params) {
  return service.delete(url, {
    params, // get 请求时带的参数
    timeout: 10000,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      token: window.localStorage.getItem('token') || ''
    }
  })
}

// 自定义post
export function postJson(url, data) {
  return service.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      token: window.localStorage.getItem('token') || ''
    },
    transformRequest: [(data) => {
      return JSON.stringify(data)
    }],
  })
}


export function post(url, data) {
  return service.post(url, data, {
    // timeout: 10000,
    transformRequest: [(data) => {
      let result = ''
      Object.keys(data).forEach((key) => {
        if (!Object.is(data[key], undefined) && !Object.is(data[key], null)) {
          result += encodeURIComponent(key) + '=' + encodeURIComponent(data[key]) + '&'
        }
      })
      return result
    }]
  })
}

export function upload(url, data) {
  return service.post(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      token: window.localStorage.getItem('token') || ''
    }
  })
}

export function postFrom(url, data) {
  return service.post(url, qs.stringify(data), {
    timeout: 10000,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      token: window.localStorage.getItem('token') || ''
    }
  })
}

// 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'

export function bascUrl() {
  return reqUrl
}


// 退出
async function logout() {
  router.push(`/login`)
}


export default service
